import { AppBar, Avatar, Backdrop, CircularProgress, Grid, IconButton, Link, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { Company } from '../../common/models/Company';
import { NewsReport } from '../../common/models/NewsReport';
import { getAccessKey, getNews } from '../utils/common';
import FilterPannel from './FilterPannel';
import NewsPannel from './NewsPannel';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        width: '100%',
        margin: '0px',
    },
    header: {
        padding: theme.spacing(3),
        width: '100%',
        textAlign: 'left',
        color: '#ffff',
        backgroundColor:'#14A5D2',
        ...theme.typography.button
    },
    panel: {
        width: '100%',
        margin: '0px',
        alignContent: 'center'
    },
    timelinePanel: {
        width: '100%',
        margin: '0px',
        alignContent: 'right'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    daySelect: {
        alignContent: 'center',
        width: '100%'
    },
    logo: {
        maxWidth: 90,
        marginRight: '10px',    
    }
}));

const cookies = new Cookies();

const Main: React.FC = () => {

    const classes = useStyles();

    const [newsReport, setNewsReport] = useState<NewsReport>({});
    const [mcap, setMcap] = useState<Number>(cookies.get('mcap') ? cookies.get('mcap') : 20000000);
    const [sector, setSector] = useState(cookies.get('sector') ? cookies.get('sector') : undefined);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [selectedSectors, setSelectedSectors] = useState<Array<string>>([]);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    const onMcapFilter = (mcap:Number) => {
        setMcap(mcap);
        cookies.set('mcap', mcap)
    };

    const onSectorFilter = (sector) => {
        setSector(sector);
        const ss:Array<string> = [];
        sector.forEach(s => {
            if(s.selected) {
                ss.push(s.label as string);
            }            
        });
        setSelectedSectors(ss);
        cookies.set('sector', sector)
    };

    const onDateFilter = (daystring:string) => {
        setLoadingOpen(true);
        setNewsReport({});
        getNews(daystring).then(nr => {
            if(nr && nr.newsKeys) {
                nr.newsKeys.sort();
                setNewsReport(nr);
            }
            setLoadingOpen(false);
        });
    };
 
    useEffect(() => {

    }, [mcap, sector]);

     
    useEffect(() => {
        getAccessKey().then((key:string) => {
            setAuthenticated(getParameterByName('key') == key);
        });
    }, []);

    return (
        <div className="App" >
            {/* <AppBar className={classes.header} position='relative'>
                <Toolbar>
                    <img src="https://osm-general.s3-eu-west-1.amazonaws.com/sic.png" alt="SIC" className={classes.logo} />
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography variant="h5" >
                                Share Pickers
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption" >
                                <em>Daily News</em>
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar> */}
            <br></br>
            <br></br>
            {
                authenticated ? 
                <div>
                    <Grid item sm={12}>
                        <Grid container spacing={2} className={classes.panel}>
                            <Grid item xs={1} sm={2} />
                            <Grid item xs={10} sm={8}>
                                <FilterPannel defaultMarketCap={mcap} defaultSector={sector} onMcapFilter={onMcapFilter} onSectorFilter={onSectorFilter} onDateFilter={onDateFilter} />
                            </Grid>
                            <Grid item xs={1} sm={2} />
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid item sm={12}>
                        <Backdrop className={classes.backdrop} open={loadingOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Grid container spacing={2}>
                            <Grid item xs={1} sm={2}/>
                            <Grid item xs={10} sm={8}>
                                    { newsReport && newsReport.news && newsReport?.newsKeys?.map((ticker:string) => {
                                        if(ticker && newsReport && newsReport.news && newsReport!.news[ticker]) {
                                            const cn:Company = newsReport!.news[ticker];
                                            if(mcap && sector) {
                                                return (
                                                    <div>
                                                        <Grid container className={classes.daySelect} spacing={2} > 
                                                            <Grid item sm={1} />
                                                            <Grid item sm={10} >
                                                                <NewsPannel companyNews={cn}/>
                                                            </Grid>
                                                            <Grid item sm={1} />
                                                        </Grid>
                                                        <br></br>
                                                        <br></br>
                                                    </div>
                                                )
                                            }
                                        };
                                    })
                                }
                            </Grid>    
                            <Grid item xs={1} sm={2} />
                        </Grid>
                    </Grid>
                </div>
                :
                <div>
                    <Grid item sm={12}>
                        <Grid container spacing={2} className={classes.panel}>
                            <Grid item xs={1} sm={2} />
                            <Grid item xs={10} sm={8}>
                                    <Typography variant="h5" >
                                        <Link
                                            component="button"
                                            variant="h5"
                                            onClick={()=> window.open("https://www.sharepickers.com", "_blank")}
                                        >
                                            Visit Sharepickers for access details
                                        </Link>
                                    </Typography> 
                            </Grid>
                            <Grid item xs={1} sm={2} />
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    );
}

export function getParameterByName(name: string, url: string = window.location.href) {
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default Main;


function useImage(arg0: { srcList: string; }): { src: any; } {
    throw new Error('Function not implemented.');
}

