import { LinearProgress, Divider, Grid, makeStyles, Paper, Typography, Card, CardActionArea, CardContent, Button, Avatar, IconButton } from '@material-ui/core';
import React from 'react';
import Box from '@material-ui/core/Box';
import { Company } from '../../../common/models/Company';
import { News } from '../../../common/models/News';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

interface Props {
    companyNews?: Company;
}

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={50}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}`}</Typography>
        </Box>
      </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    newsCard: {
        width: '100%',
        backgroundColor: '#F7F9FA',
    },
    companyProfile: {
        width: '100%',
        backgroundColor: '#F7F9FA',
    },
    companyLogo: {
        width: '100%',
        height: '100%',
        maxWidth: '100px',
        maxHeight: '100px',
        margin: 'auto'
    },
    companyHeader: {
        paddingLeft: '7%',
        textAlign: 'left'
    },
    newsPannel: {
        width: '100%',
        alignContent: 'center',
        backgroundColor: '#F7F9FA'
    },
    newsArrCard: {
        width: '100%',
    },
    linkPannel: {
        width: '100%',
        backgroundColor: '#F7F9FA'
    },
    linkButton: {
        alignContent: 'right',
    },
    statsLeft: {
        textAlign: 'left',
        alignContent: 'left',
        paddingLeft: '3%'
    },
    statsRight: {
        textAlign: 'right',
        alignContent: 'right',
        paddingRight: '3%'
    },
    statsCenter: {
        textAlign: 'center',
        alignContent: 'center',
        width: '100%'
    },
    statsRed: {
        textAlign: 'center',
        alignContent: 'center',
        width: '100%',
        paddingTop: '10%'
    },
    statsBlue: {
        textAlign: 'center',
        alignContent: 'center',
        color: 'blue',
        width: '100%',
        paddingTop: '10%'
    },
}));

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const NewsPannel: React.FC<Props> = ({
    companyNews,
}) => {
    
    const classes = useStyles();


    let negPrice = false;
    if(companyNews && companyNews?.Change < 0) {
        negPrice = true
    }

    return (
        <Box boxShadow={5}>
            <Paper className={classes.newsCard}>
                <Grid container spacing={2}>
                    <Grid item container xs={12} sm={12} lg={12}>
                        <Grid item xs={12} sm={12} lg={2}>
                            <Box boxShadow={8} border={2} className={classes.companyLogo}>
                                <Avatar variant="square" alt={companyNews?.Name} src={companyNews?.Logo} className={classes.companyLogo} />
                            </Box>
                        </Grid>
                        <Grid item container xs={10} sm={10} lg={8} >
                            <Grid item xs={6} sm={6} lg={6}>
                                <Typography variant="h6" className={classes.statsLeft}>
                                    <b>Market Cap: </b> £{ Number(companyNews?.MarketCap as number / 1000000).toString().substring(0, 4) }M
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6}>
                                <Typography variant="h6" className={classes.statsLeft}>
                                    <b>Revenue: </b> £{ Number(companyNews?.Revenue as number / 1000000).toString().substring(0, 4) }M
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6}>
                                <Typography variant="h6" className={classes.statsLeft}>
                                    <b>Price: </b> {companyNews?.LastPrice}p
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6}>
                                <Typography variant="h6" className={classes.statsLeft}>
                                    <b>Volume: </b> {numberWithCommas(companyNews?.Volume)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6}>
                                <Typography variant="h6" className={classes.statsLeft}>
                                    <b>RSI: </b> { Math.round(companyNews?.RSI as number) }
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6}>
                                <Typography variant="h6" className={classes.statsLeft}>
                                    <b>AV.Volume (30): </b> { numberWithCommas(Math.round(companyNews?.VolumeAvg30 as number)) }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2}>
                            <Box boxShadow={8} border={2} className={classes.companyLogo}>
                                <Typography variant="h6">
                                    <b>Change</b>
                                    { 
                                        negPrice ? 
                                        <Typography variant="h4" color="error" className={classes.statsRed}>
                                            { Math.round(companyNews?.ChangePercent as number)}% 
                                        </Typography>
                                        :
                                        <Typography variant="h4" color="primary" className={classes.statsBlue}>
                                            { Math.round(companyNews?.ChangePercent as number)}% 
                                        </Typography>
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} lg={12}>
                        <Grid item xs={10} sm={10} lg={8}>
                            <Typography variant="h4" className={classes.companyHeader}>
                                { companyNews?.Name as string} ({companyNews?.Ticker})
                            </Typography>
                            <Typography variant="h6" className={classes.companyHeader}>
                                <i>{ companyNews?.Sector }</i> ◦ <i>{ companyNews?.Industry }</i> 
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={4} className={classes.statsRight}>
                            <IconButton onClick={()=> window.open(`https://www.tradingview.com/chart?symbol=LSE%3A${companyNews?.Ticker}`, "_blank")} >
                                <Avatar variant="square" src="https://osm-general.s3-eu-west-1.amazonaws.com/tv2.png"/>
                            </IconButton>
                            <IconButton onClick={()=> window.open(`https://www.voxmarkets.co.uk/listings/LON/${companyNews?.Ticker}`, "_blank")} >
                                <Avatar variant="square" src="https://osm-general.s3-eu-west-1.amazonaws.com/vox2.png"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} lg={12}>
                            <Grid item xs={3} sm={3} lg={3}/>
                            <Grid item xs={6} sm={6} lg={6}>
                                <Divider variant="middle" />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3}/>
                    </Grid>
                    <Grid item container xs={12} sm={12} lg={12}>
                        <Typography variant="h5" className={classes.statsCenter}>
                            <b>News</b>
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} lg={12}>
                        {
                            companyNews && companyNews?.News && companyNews?.News.map((n:News) => {
                                return(
                                    <div className={classes.newsArrCard}>
                                        <Box>
                                            <Grid item container xs={12} sm={12} lg={12}>
                                                <Grid item xs={1} sm={1} lg={1}/>
                                                <Grid item container xs={10} sm={10} lg={10} >
                                                    <Card className={classes.newsArrCard}>
                                                        <CardActionArea onClick={()=> window.open(n.NewsURL, "_blank")}>
                                                            <Button>
                                                                <CardContent>
                                                                    <Typography gutterBottom variant="h6" component="h2">
                                                                        {n.Title}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                                        {
                                                                            n.DateTime
                                                                        }
                                                                    </Typography>
                                                                </CardContent>
                                                            </Button>
                                                            {
                                                                n.Analysis && n.Analysis.NotableInvestors  && n.Analysis.NotableInvestors.length > 0 && ( 
                                                                    <Alert severity="warning">
                                                                        <AlertTitle>Key Investors Detected</AlertTitle>
                                                                        {
                                                                        n.Analysis.NotableInvestors.map((nii:string) => {
                                                                            return(
                                                                                <div>
                                                                                    <strong>{nii}</strong>
                                                                                </div>                                                                               
                                                                            )
                                                                        })
                                                                        }
                                                                    </Alert>
                                                                )
                                                            }
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={1} sm={1} lg={1}/>
                                            </Grid>
                                        </Box>
                                        <br></br>
                                    </div>
                                   
                                );
                            })
                        }
                    </Grid>                   
                </Grid>
            </Paper>
        </Box>
    );
};

export default NewsPannel;


